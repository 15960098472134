html, body {
  background-color: #ffffff;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.page-section {
  padding: 4.0rem 0 2.5rem 0;
}

.section-heading {
  color: #22313d;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.25rem;
}

.banner-wrapper {
  background: #00548f;
	color: #333;
  border-radius: 3px;
  max-height: 9.5em;
	margin-top: 1.5em;
	padding: 0;
	overflow-x: hidden;
  overflow-y: hidden;
  position: relative;

  .banner-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    .banner-heading-icon {
      width: auto;
      height: 2.0rem;
    }

    .banner-link {
      color: #6FC3FF;
      font-weight: 500;
    }
  }
}

#animated-cloud-container {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
}

/* KEYFRAMES */

@-webkit-keyframes animateCloud {
  0% {
      margin-left: 100%;
  }
  100% {
      margin-left: -1000px;
  }
}

@-moz-keyframes animateCloud {
  0% {
      margin-left: 100%;
  }
  100% {
      margin-left: -1000px;
  }
}

@keyframes animateCloud {
  0% {
      margin-left: 100%;
  }
  100% {
      margin-left: -1000px;
  }
}

/* ANIMATIONS */

.x1 {
  -webkit-animation: animateCloud 140s linear infinite;
  -moz-animation: animateCloud 140s linear infinite;
  animation: animateCloud 140s linear infinite;

  -webkit-transform: scale(0.2);
  -moz-transform: scale(0.2);
  transform: scale(0.2);

  margin-top: 1.75em;
  opacity: 0.5;
}

.x2 {
  -webkit-animation: animateCloud 65s linear infinite;
  -moz-animation: animateCloud 650s linear infinite;
  animation: animateCloud 65s linear infinite;

  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);

  margin-top: -6.0em;
  opacity: 0.6;
}

.x3 {
  -webkit-animation: animateCloud 115s linear infinite;
  -moz-animation: animateCloud 115s linear infinite;
  animation: animateCloud 115s linear infinite;

  -webkit-transform: scale(0.45);
  -moz-transform: scale(0.45);
  transform: scale(0.45);

  margin-top: -5.5em;
  opacity: 0.8;
}

.x4 {
  -webkit-animation: animateCloud 45s linear infinite;
  -moz-animation: animateCloud 45s linear infinite;
  animation: animateCloud 45s linear infinite;

  -webkit-transform: scale(0.55);
  -moz-transform: scale(0.55);
  transform: scale(0.55);

  margin-top: -7.0em;
  opacity: 0.7;
}

.x5 {
  -webkit-animation: animateCloud 85s linear infinite;
  -moz-animation: animateCloud 85s linear infinite;
  animation: animateCloud 85s linear infinite;

  -webkit-transform: scale(0.65);
  -moz-transform: scale(0.65);
  transform: scale(0.65);

  margin-top: -6.5em;
  opacity: 0.9;
}

/* OBJECTS */

.cloud {
  background: #0c67a8;
  background: -moz-linear-gradient('to bottom',  #fff 5%, #f1f1f1 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(5%,#fff), color-stop(100%,#f1f1f1));
  background: -webkit-linear-gradient('to bottom',  #fff 5%,#f1f1f1 100%);
  background: -o-linear-gradient('to bottom',  #fff 5%,#f1f1f1 100%);
  background: -ms-linear-gradient('to bottom',  #fff 5%,#f1f1f1 100%);
  background: linear-gradient('to bottom',  #fff 5%,#f1f1f1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#f1f1f1',GradientType=0 );

  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;

  -webkit-box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);

  height: 120px;
  position: relative;
  width: 350px;
}

.cloud:after, .cloud:before {
  background: #0c67a8;
  content: '';
  position: absolute;
  z-indeX: -1;
}

.cloud:after {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;

  height: 100px;
  left: 50px;
  top: -50px;
  width: 100px;
}

.cloud:before {
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
  border-radius: 200px;

  width: 180px;
  height: 180px;
  right: 50px;
  top: -90px;
}

// Responsive styling
@media (max-width: 835px) {
  .banner-wrapper {
    max-height: 10.5em;
  }
}

@media (max-width: 600px) {
  .banner-wrapper {
    max-height: 12.0em;
  }
}

@media (max-width: 535px) {
  .banner-wrapper {
    min-height: 18.0em;

    .banner-content {
      .banner-heading {
        .banner-heading-icon {
          display: none;
        }
      }
    }

    .animated-cloud-container {
      display: none;
    }
  }
}

@media (max-width: 395px) {
  .banner-wrapper {
    min-height: 18.25em;
  }
}

@media (max-width: 375px) {
  .banner-wrapper {
    min-height: 19.25em;
  }
}

@media (max-width: 325px) {
  .banner-wrapper {
    min-height: 20.75em;
  }
}
