.analysis-page-container {
  .meta-analysis-content-container {
    .gene-search-container {
      .gene-search-content {
        background-color: $gray-300;

        form {
          position: relative;
        }

        button.submit-gene-search {
          line-height: 0;
        }

        .search-error-message {
          color: $orange;
          font-size: larger;
        }

        .recent-search-terms-label {
          font-size: larger;
        }

        button.saved-search {
          cursor: pointer;
          font-weight: 600;
        }
      }
    }

    /* loading UI */
    .loading-ui {
      img.in-progress-spinner {
        animation-name: spinner;
        animation-duration: 1.75s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        -webkit-filter: opacity(50%);
        filter: opacity(50%);
        height: 4%;
        width: 4%;
      }
  
      @keyframes spinner {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
      }
    }

    table {
      border-collapse: collapse;
      margin-bottom: 0;
      text-align: center;

      th, td {
        border: 1px solid #dee2e6;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        vertical-align: middle;
      }

      tr:first-child th, tr:first-child td {
        border-top: 0;
      }

      tr th:first-child, tr td:first-child {
        border-left: 0;
      }

      tr:last-child td {
        border-bottom: 0;
      }

      tr th:last-child, tr td:last-child {
        border-right: 0;
      }
    }

    .summary-stat-container {
      border: 1px solid #dee2e6;
      border-radius: 4px;
    }

    .analysis-input-table-wrapper {
      border: 1px solid #dee2e6;
      border-radius: 4px;
      margin-bottom: 1.0em;
      padding: 0;
    }

    .note-comment {
      color: $gray-500;

      .material-icons {
        line-height: 0;
        margin-right: 0.25rem;
      }
    }

    .plot-image-wrapper {
      border: 1px solid #dee2e6;
      border-radius: 4px;
    }

    .plot-image-wrapper,
    .plot-notes-wrapper {
      &.hidden {
        display: none;
      }
    }
  }
}

/* auto-suggest styles */
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  display: inline-block;
  width: 270px;
  min-width: 0;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border: 1px solid $dark;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  flex: 1 1 auto;
  margin-bottom: 0;
  z-index: 3;

  &::placeholder {
    color: $gray-500;
    opacity: 1;
  }
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  background-color: #fff;
  border: 1px solid $dark;
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  position: absolute;
  top: calc(1.5em + .75rem + 1px);
  width: 270px;
  max-height: 360px;
  overflow-y: scroll;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: .35rem .75rem;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $gray-200;
}

/* responsive styles */
@include media-breakpoint-down(lg) {
  .analysis-page-container {
    .meta-analysis-content-container {
      flex-direction: column;
      justify-content: start !important;

      .gene-search-container {
        .recent-search-terms {
          margin-top: 1.0em;
          margin-left: 0;
        }
      }

      .meta-analysis-data-container {
        padding-left: 0;
        padding-right: 0;

        .meta-analysis-forest-plot {
          margin-left: 0;
          margin-bottom: 1.0rem;
        }
      }

      .meta-analysis-data-content {
        flex-direction: column;
        justify-content: start !important;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .analysis-page-container {
    margin-left: 1.5em;
    margin-right: 1.5em;

    form {
      .input-group.ml-2 {
        margin-right: 0.5rem;
      }
    }

    .react-autosuggest__container {
      width: calc(100% - 50px);
    }

    .react-autosuggest__input,
    .react-autosuggest__suggestions-container--open {
      width: 100%;
    }
  }
}

@media (max-width: 360px) {
  .react-autosuggest__input {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &::placeholder {
      font-size: 0.8rem;
    }
  }
}
